import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { LOGIN_SIGNUP_METHODS } from "enums";
import { useAppActions } from "store/AppSlice";
import { useAuthActions } from "store/AuthSlice";
import { getAuthFromLocalStorage } from "utils/authUtils";
import { jjLogger } from "utils/logUtils";
import { isAnAnonEmail } from "utils/anonymousUtils";

import useTrackAccountEvents from "./useTrackAccountEvents";
import useRecaptcha from "./useRecaptcha";
import useFetchStatus from "./useFetchStatus";

const ANON_PASSWORD = "Jibjab123!";

const getToken = () => {
  const auth = getAuthFromLocalStorage();
  return auth.accessToken;
};

const generateAnonUserAttributes = () => {
  return {
    data: {
      type: "users",
      attributes: {
        "first-name": "jibjab",
        password: ANON_PASSWORD,
        role: "anonymous",
        "registration-source": "web",
        email: `${uuidv4()}@jibjab.com`,
      },
    },
  };
};

const useUsers = () => {
  const {
    loadCurrentUser,
    setCurrentUserLoadingStatus,
    createUser,
    updateUser,
    clearErrors,
    reactivateSubscription,
    deactivateSubscription,
  } = useAppActions();

  const { trackLoginEvents, trackSignUpEvents } = useTrackAccountEvents();

  const authActions = useAuthActions();
  const { needsRefreshScheduled } = useSelector((state) => state.AuthSlice);

  const { recaptcha } = useRecaptcha();

  const { currentUser, currentUserLoadingStatus, errors } = useSelector((state) => state.AppSlice);
  const { isFulfilled, isFailed } = useFetchStatus(currentUserLoadingStatus);
  const currentUserLoaded = isFulfilled || isFailed;

  const logAnonEmailIssues = (msg) => {
    const anonEmail = isAnAnonEmail(currentUser.email);
    if (!anonEmail) {
      jjLogger.log(`useUsers: ${msg} - User email does not match anonymous pattern: ${JSON.stringify(currentUser)}`);
    }
    jjLogger.log(`useUsers: ${msg} - currentUser: ${JSON.stringify(currentUser)}`);
  };

  const createAnonUser = () => {
    const attributes = generateAnonUserAttributes();
    return createUser(attributes);
  };

  const signIn = ({ password = ANON_PASSWORD, email, username = email, relogin = false }) => {
    let attributes = {};
    if (currentUser && !relogin) {
      const anonymousToken = getToken();
      attributes = { grantType: "password", username, password, anonymousToken };
      logAnonEmailIssues("signIn");
    } else {
      attributes = { grantType: "password", username, password };
    }

    return recaptcha({ actionName: "login" }).then((gToken) => {
      return authActions.signIn({ ...attributes, g_token: gToken });
    });
  };

  const ensureCurrentUser = () => {
    if (currentUser) return Promise.resolve(currentUser);

    return createAnonUser()
      .then(({ attributes: { email: username } }) => signIn({ username }))
      .then(() => loadCurrentUser());
  };

  const regularSignUp = ({ username, password }) => {
    if (currentUser) {
      const attributes = {
        data: {
          type: "users",
          attributes: {
            email: username,
            password,
            registrationSource: "web",
            role: "regular",
            firstName: "",
          },
        },
      };
      logAnonEmailIssues("regularSignUp");
      return updateUser(attributes);
    }
    const attributes = {
      data: {
        type: "users",
        attributes: {
          email: username,
          password,
          registrationSource: "web",
        },
      },
    };
    return createUser(attributes);
  };

  const thirdPartySignIn = (provider, accessToken) => {
    const attributes = { grantType: "assertion", provider, token: accessToken };

    if (currentUser) {
      attributes.anonymousToken = getToken();
      logAnonEmailIssues(`thirdPartySignIn for ${attributes.provider}`);
    }

    return authActions.signIn(attributes);
  };

  const thirdPartySignUp = (provider, { name, email, password, id, accessToken }) => {
    const attributes = {
      data: {
        type: "users",
        attributes: {
          email,
          firstName: name,
          password,
          registrationSource: "web",
        },
        relationships: {
          identities: {
            data: [
              {
                type: "identities",
                attributes: {
                  provider,
                  uid: provider === LOGIN_SIGNUP_METHODS.APPLE ? undefined : id,
                  token: accessToken,
                  "token-secret": provider === LOGIN_SIGNUP_METHODS.APPLE ? "" : undefined,
                },
              },
            ],
          },
        },
      },
    };

    if (currentUser) {
      attributes.data.attributes.role = "regular";
      logAnonEmailIssues(`${provider}SignUp`);
      return updateUser(attributes);
    }

    return createUser(attributes);
  };

  const handleThirdPartySignIn = async (provider, accessToken) => {
    await thirdPartySignIn(provider, accessToken);

    const user = await loadCurrentUser();

    trackLoginEvents(user, provider);

    return user;
  };

  const handleThirdPartySignUp = async (provider, { name, email, id, accessToken }) => {
    const values = { name, email, password: ANON_PASSWORD, id, accessToken };

    await thirdPartySignUp(provider, values);
    await signIn(values);

    const user = await loadCurrentUser();

    trackSignUpEvents(user, provider);

    return user;
  };

  const thirdPartySignInSignUp = async (provider, { name, email, id, accessToken }) => {
    try {
      return await handleThirdPartySignIn(provider, accessToken);
    } catch (error) {
      if (error?.response?.status !== 401) throw error;
      return handleThirdPartySignUp(provider, { name, email, id, accessToken });
    }
  };

  const fbSignInSignUp = async ({ name, email, id, accessToken }) => {
    return thirdPartySignInSignUp(LOGIN_SIGNUP_METHODS.FACEBOOK, { name, email, id, accessToken });
  };

  const appleSignInSignUp = async ({ name, email, accessToken }) => {
    return thirdPartySignInSignUp(LOGIN_SIGNUP_METHODS.APPLE, { name, email, accessToken });
  };

  const googleSignInSignUp = async (token) => {
    await thirdPartySignIn("google-new", token);
    return loadCurrentUser();
  };

  return {
    loadCurrentUser,
    currentUserLoaded,
    currentUserLoadingStatus,
    setCurrentUserLoadingStatus,
    currentUser,
    createAnonUser,
    updateUser,
    reactivateSubscription,
    deactivateSubscription,
    signIn,
    signOut: authActions.signOut,
    ensureCurrentUser,
    regularSignUp,
    fbSignInSignUp,
    thirdPartySignInSignUp,
    thirdPartySignIn,
    thirdPartySignUp,
    appleSignInSignUp,
    googleSignInSignUp,
    errors,
    clearErrors,
    needsRefreshScheduled,
    scheduleRefresh: authActions.scheduleRefresh,
  };
};

export default useUsers;
