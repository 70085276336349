import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";
import TemplateGroupSharedProperties from "presenters/shared/TemplateGroup";

export default new Presenter(
  {
    ...TemplateGroupSharedProperties,
    coverImage: PropTypes.string,
    morphablePacks: PropTypes.arrayOf(PropTypes.shape({})),
    templateGroupType: PropTypes.string,
    upsellImage: PropTypes.string,
  },
  {
    castNumOptions() {
      return [1];
    },
    keywords(mpg) {
      return `${this.tags(mpg)} ${this.name(mpg)} AI Photos`;
    },
    template(mpg) {
      return this.morphablePacks(mpg)?.[0];
    },
    metaImage(mpg) {
      return this.upsellImage(mpg);
    },
    metaFomoImage(mpg) {
      return this.upsellImage(mpg);
    },
    mainTitle(mpg) {
      return `${this.name(mpg)} AI Photos`;
    },
    algoliaId(mpg) {
      return `morphable-${this.id(mpg)}`;
    },
    analyticsItem(t) {
      return {
        item_id: this.id(t),
        item_name: this.name(t),
      };
    },
  },
);
