/* global DetectUserAgent */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";

import { useRouter } from "hooks";
import { ExperimentContext } from "contexts/experiment-context";
import LoaderBricks from "components/LoaderBricks";
import { experimentViewedEvent } from "tracking/jibjab/tracking";
import { jjLogger } from "utils/logUtils";

const FeatureFlags = ({ children, trackingID }) => {
  const [growthbook, setGrowthbook] = useState(null);
  const { setExperimentId } = useContext(ExperimentContext);
  const { history, location } = useRouter();

  const handleNavigate = (url) => history.replace(url);
  const handleTrackingCallback = (experiment, result) => {
    setExperimentId(`${experiment.key}:${result.key}`);
    experimentViewedEvent(experiment.key, result.key);
  };

  useEffect(() => {
    const gb = new GrowthBook({
      apiHost: "https://cdn.growthbook.io",
      clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
      enableDevMode: true,
      trackingCallback: handleTrackingCallback,
      navigate: handleNavigate,
      navigateDelay: 0,
    });
    gb.init({ streaming: true });

    setGrowthbook(gb);
  }, []);

  useEffect(() => {
    if (!growthbook || !trackingID || DetectUserAgent.isPrerender()) return;

    jjLogger.log(`FeatureFlags.js: useEffect(): growthbook.setAttributes id: ${trackingID}`);
    growthbook.setAttributes({
      id: trackingID,
    });
  }, [growthbook, trackingID]);

  useEffect(() => {
    if (!growthbook) return;

    growthbook.setURL(window.location.href);
  }, [growthbook, location]);

  if (!growthbook) return <LoaderBricks />;
  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
};

FeatureFlags.propTypes = {
  children: PropTypes.node,
  trackingID: PropTypes.string,
};

export default FeatureFlags;
