import { isNil } from "ramda";

import { signUpEvent, loginEvent } from "tracking/jibjab/tracking";
import { trackMakeEvent, IN_FLOW_REGISTRATION } from "utils/makeEventUtils";
import { trackCordialLoginRegister } from "utils/cordialUtils";
import { sendRegistrationSuccessEvent } from "utils/gtmUtils";
import { trackSignUpSuccess, trackLoginSuccess } from "tracking/google/analytics";
import { trackCompleteRegistration } from "tracking/facebook/conversion";
import { getEcard, getInFlow, getTemplateGroup, isMake } from "utils/lastEcardUtil";

const useTrackAccountEvents = () => {
  const trackSignUpEvents = (user, method) => {
    const currentUrl = window.location.href;
    const ecard = getEcard();
    const inFlow = getInFlow();
    const templateGroup = getTemplateGroup();

    trackCordialLoginRegister(user?.email);
    trackSignUpSuccess(method, inFlow, templateGroup);
    signUpEvent(user);
    trackCompleteRegistration(user, currentUrl);
    sendRegistrationSuccessEvent(user);
    if (!isNil(ecard) && isMake()) {
      trackMakeEvent(ecard, IN_FLOW_REGISTRATION);
    }
  };

  const trackLoginEvents = (user, method) => {
    const inFlow = getInFlow();
    const templateGroup = getTemplateGroup();

    trackCordialLoginRegister(user?.email);
    loginEvent(user);
    trackLoginSuccess(method, inFlow, templateGroup);
  };

  return {
    trackSignUpEvents,
    trackLoginEvents,
  };
};

export default useTrackAccountEvents;
