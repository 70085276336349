import MorphedPackPresenter from "presenters/MorphedPackPresenter";
import MakePresenter from "presenters/MakePresenter";
import InvitePresenter from "presenters/InvitePresenter";
import TemplateGroupPresenter from "presenters/TemplateGroupPresenter";
import UserPresenter from "presenters/UserPresenter";
import { appRoutes } from "routes";
import { jjLogger } from "utils/logUtils";
import useUtmsParams from "hooks/useUtmsParams";
import useLastEcard from "hooks/useLastEcard";

const useRedirectAfterAuth = () => {
  const { utmsParams } = useUtmsParams();
  const { templateGroup, ecard, isInvite, isMorphable } = useLastEcard();

  const buildInvitePreviewPath = () => {
    const id = InvitePresenter.id(ecard);

    return appRoutes.inviteMakePreviewPath(id);
  };

  const buildMakePreviewPath = () => {
    const makeId = MakePresenter.id(ecard);
    const tgId = TemplateGroupPresenter.id(templateGroup);

    return appRoutes.makeViewPath(tgId, makeId);
  };

  const buildMorphedPackPreviewPath = () => {
    const morphableId = MorphedPackPresenter.id(ecard);
    return appRoutes.syaiPackPreviewPath(morphableId);
  };

  const buildPreviewPath = () => {
    if (isInvite) return buildInvitePreviewPath();
    if (isMorphable) return buildMorphedPackPreviewPath();

    return buildMakePreviewPath();
  };

  const buildRedirectPath = (user) => {
    const needsPayment = UserPresenter.notPaid(user) && TemplateGroupPresenter.premium(templateGroup);
    const previewPath = buildPreviewPath();
    const path = needsPayment ? appRoutes.paymentPath() : previewPath;

    jjLogger.logDebug(`useLastEcard.js: buildRedirectPath(): path: ${path}`);
    return { pathname: path, state: { referrer: "inFlow" }, search: utmsParams };
  };

  return {
    buildRedirectPath,
  };
};

export default useRedirectAfterAuth;
